import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { SubSystem } from '../../../enumerators/classifier.enum';
import { IConfig } from '../../../models/config.interface';
import { Location } from '@angular/common'

@Component({
  selector: 'app-public-search',
  templateUrl: './public-search.component.html'
})
export class PublicSearchComponent implements OnInit {
  module: SubSystem = SubSystem.PUB;
  publicSearchListFormId: string = this.environment.formIds.publicSearchListFormId;
  inboxImage = '/assets/images/inbox.jpg';

  constructor(
    private location: Location,
    @Inject(APP_CONFIG) private readonly environment: IConfig
  ) {
    
  }
  ngOnInit() {
    this.inboxImage = '/assets/images/' + this.environment.env.projectCode + '-inbox.jpg';
  }
 
  back() {
    this.location.back();
  }

}
